import * as React from "react"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import { ContactFormContextProvider } from "../../core/contexts/contactForm"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import Card from "../../components/card/card"
import Divider from "../../components/divider/divider"
import TextButton from "../../components/buttons/textButton"
import ContactForm from "../../components/contactForm/contactForm"

const description =
  "Create collections of content which inform and entertain, but also persuade and ultimately drive the behaviours that align with business outcomes."

const CreatingAContentEcosystemPage = () => {
  return (
    <Layout>
      <Seo
        title="Solutions | Creating a Content Ecosystem"
        description={description}
      />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>Creating a Content Ecosystem</h4>
          <p className="subtitle1">{description}</p>
        </div>
        <Divider />
      </section>
      <section className="mt-5">
        <Card canHover={false} isPrimary={true} isPadded={false}>
          <div className="container pt-2 pb-2">
            <h6 className="mw-800">
              70 percent of people would rather get information about a company
              or learn something from an article or blog post rather than from a
              traditional advertisement.
            </h6>
            <TextButton
              label="Source: Demand Metric | Content Marketing Infographic"
              icon={faArrowRight}
              isExternalLink={true}
              hasNoPaddingStart={true}
              href="https://www.demandmetric.com/content/content-marketing-infographic"
            />
          </div>
        </Card>
      </section>
      <section className="container mt-5">
        <div className="mw-800">
          <h6>Put your organic, custom content above all</h6>
          <p>
            Ad targeting is going to get increasingly more complex with new
            privacy regulations being introduced and the implementation of{" "}
            <a
              href="https://marketingland.com/the-third-party-browser-tracking-cookie-is-dead-whats-next-263804"
              className="text-tertiary text-hover-black"
              target="_blank"
              rel="noreferrer"
            >
              cookie-blocking technology being led by Apple and Firefox
            </a>
            . Advertising will continue to become increasingly more expensive
            and complex. And not to mention, consumers prefer content to
            advertising.{" "}
          </p>
        </div>
      </section>
      <section className="mt-5">
        <Card canHover={false} isPrimary={true} isPadded={false}>
          <div className="container pt-2 pb-2">
            <h6 className="mw-800">
              Content marketing generates over three times as many leads as
              outbound marketing and costs 62% less.
            </h6>
            <TextButton
              label="Source: Demand Metric | Content Marketing Infographic"
              icon={faArrowRight}
              isExternalLink={true}
              hasNoPaddingStart={true}
              href="https://www.demandmetric.com/content/content-marketing-infographic"
            />
          </div>
        </Card>
      </section>
      <section className="container mt-5">
        <div className="mw-800">
          <h6>Generate more leads while using less money</h6>
          <p>
            While many inexperienced marketers assume that content marketing is
            expensive, the fact is that it&apos;s often cheaper than traditional
            marketing methods.
          </p>
        </div>
      </section>
      <section className="mt-5">
        <Card canHover={false} isPrimary={true} isPadded={false}>
          <div className="container pt-2 pb-4">
            <h6 className="mw-800">
              How Do We Create an Effective Content Ecosystem?
            </h6>
            <p className="mw-800">
              We ask the right questions and provide the most optimal answers
              based on busineess and customer data to ensure that your content
              ecosystem, or content strategy, is ripe for today and the
              future&apos;s digital customers.
            </p>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">Do you have the right content?</h6>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">Is it in the right formats?</h6>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">
                    Should you be using personalized content?
                  </h6>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">
                    Are there better ways to distribute your content?
                  </h6>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">
                    How can your content be more engaging?
                  </h6>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">
                    Are there other ways to monetize your content?
                  </h6>
                </Card>
              </div>
            </div>
          </div>
        </Card>
      </section>
      <section className="container mt-5">
        <Card isPrimary={false} canHover={false}>
          <h6 className="mw-800 m-0">
            Winning the content game is about effective content ecosystems
          </h6>
        </Card>
      </section>
      <section className="container mt-5">
        <div className="mw-800">
          <h6>Results in five steps</h6>
          <p>
            We develop content and content ecosystems using the following steps:
          </p>
          <ul>
            <li>Inventory Content &amp; Measure Effectiveness</li>
            <li>Content Voice &amp; Style</li>
            <li>Content Mission Statement &amp; Goals</li>
            <li>Content Acquisition</li>
            <li>Content Roadmap</li>
          </ul>
        </div>
      </section>
      <section className="container mt-5">
        <p>Required fields are marked with an asterisk (*).</p>
        <Card canHover={false}>
          <h6 className="mb-4 mw-800">
            Let&apos;s talk about how content creation can drive increased
            growth and revenue for your business.
          </h6>
          <ContactFormContextProvider>
            <ContactForm
              defaultSubject="digital_content"
              subjects={[
                { value: "digital_content", label: "Digital Content" },
              ]}
            />
          </ContactFormContextProvider>
        </Card>
      </section>
    </Layout>
  )
}

export default CreatingAContentEcosystemPage
